import { required } from 'vuelidate/lib/validators';
import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'addEditComponentMaster',
  components: { DatePicker },
  props: ['componentMasterData'],
  data() {
    return {
      showValueSetModal: false,
      parent_value_set_id: null,
      vsetCode: null,
      unsubscribe: null,
      editMode: false,
      isSuccess: false,
      showAlert: false,
      respMessage: '',
      loader: false,
      componentGroup: { id: null, name: null },
      uomClass: { value: null, text: null, code: null },
      uom: { value: null, text: null },
      revenueAcc: { value: null, text: null },
      waiverAcc: { value: null, text: null },
      compId: 0,
      groupId: 0,
      compCode: null,
      compName: null,
      compDisplayName: null,
      sac: null,
      hsn: null,
      startDate: null,
      endDate: null,
      toleranceRateUp:0,
      toleranceRateDown:0,
      active: true
    };
  },
  validations: {
    componentGroup: {
      name: { required }
    },
    compCode: {
      required
    },
    compName: { required },
    compDisplayName: { required },
    startDate: { required },
  },
  mounted() {
    if (this.componentMasterData) {
      this.getComponentDetailsById(this.componentMasterData.comp_id);
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditComponentMaster();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        // if (actionName === 'upload') {
        //   this.eventBus.$emit('commonUpload', { id: this.compForm.comp_id });
        // }
      }
    });
  },
  methods: {
    addEditComponentMaster() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          comp_id: this.componentMasterData
            ? this.componentMasterData.comp_id
            : 0,
          group_id: this.componentGroup.id,
          comp_code: this.compCode,
          comp_name: this.compName,
          comp_display_name: this.compDisplayName,
          uom_class: this.uomClass.value,
          uom: this.uom.value,
          comp_revenue_Acc: this.revenueAcc.value,
          comp_waiver_Acc: this.waiverAcc.value,
          sac: this.sac,
          hsn: this.hsn,
          start_date: this.startDate,
          end_date: this.endDate,
          tolerance_rate_up: this.toleranceRateUp,
          tolerance_rate_down: this.toleranceRateDown,
          active: this.active
        };
        this.loader = true;
        this.$store
          .dispatch('masterData/addEditComponentMaster', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.$emit('updateList');
              this.compId = response.data.data.comp_id;
              this.editMode = false;
              this.isSuccess = true;
              this.respMessage = response.data.message;
            } else {
              this.isSuccess = false;
              this.respMessage = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getComponentDetailsById(comp_id) {
      this.loader = true;
      this.$store
        .dispatch('masterData/getComponentDetailsById', comp_id)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.componentGroup.name = result.group_code;
            this.componentGroup.id = result.group_id;
            this.compName = result.comp_name;
            this.compCode = result.comp_code;
            this.compDisplayName = result.comp_display_name;
            this.uomClass.text = result.uom_class_meaning;
            this.uomClass.value = result.uom_class;
            this.uom.text = result.uom_meaning;
            this.uom.value = result.uom;
            this.revenueAcc.text = result.comp_revenue_Acc_meaning;
            this.revenueAcc.value = result.comp_revenue_Acc;
            this.waiverAcc.text = result.comp_waiver_Acc_meaning;
            this.waiverAcc.value = result.comp_waiver_Acc;
            this.sac = result.sac;
            this.hsn = result.hsn;
            this.startDate = result.start_date;
            this.endDate = result.end_date;
            this.toleranceRateUp = result.tolerance_rate_up;
            this.toleranceRateDown = result.tolerance_rate_down;
            this.active = result.active;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedvalueSet(item) {
      if (
        this.vsetCode === appStrings.VALUESETTYPE.VATIKA_GL_Accounts &&
        this.accountType === 'revenue'
      ) {
        this.revenueAcc.value = item.value_code;
        this.revenueAcc.text = item.value_meaning;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.VATIKA_GL_Accounts &&
        this.accountType === 'waiver'
      ) {
        this.waiverAcc.value = item.value_code;
        this.waiverAcc.text = item.value_meaning;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.UOM_CLASS) {
        this.uomClass.value = item.value_code;
        this.uomClass.text = item.value_meaning;
        this.uomClass.code = item.value_set_dtl_id;
        this.uom.value = null;
        this.uom.text = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.UOM) {
        this.uom.value = item.value_code;
        this.uom.text = item.value_meaning;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_COMP_GROUP_TABLE
      ) {
        this.componentGroup = {
          id: item.value_set_dtl_id,
          name: item.value_code
        };
      }
      // this.compForm.group_id = Number(item.value_set_dtl_id);
      // this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode, accType) {
      this.vsetCode = vsetCode;
      this.accountType = accType;
      this.parent_value_set_id = null;
      this.showValueSetModal = true;
      if (this.vsetCode === appStrings.VALUESETTYPE.UOM) {
        this.parent_value_set_id = this.uomClass.code;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetName) {
      switch (vsetName) {
        case this.revenueAcc.value:
          this.revenueAcc.text = null;
          this.revenueAcc.value = null;
          break;
        case this.waiverAcc.value:
          this.waiverAcc.text = null;
          this.waiverAcc.value = null;
          break;
        case this.uomClass.value:
          this.uomClass.text = null;
          this.uomClass.value = null;
          this.uom.text = null;
          this.uom.value = null;
          break;
        case this.uom.value:
          this.uom.text = null;
          this.uom.value = null;
          break;
        case this.componentGroup.id:
          this.componentGroup.name = null;
          this.componentGroup.id = null;
          break;
      }
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.startDate);
    },
    validateToleranceUp(num){
        if(num<=100){
          this.toleranceRateUp=num;
        } else{
          this.toleranceRateUp=null;
        }
    },
    validateToleranceDown(num){
      if(num<=100){
        this.toleranceRateDown=num;
      } else{
        this.toleranceRateDown=null;
      }
  }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
