import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import addEditComponentMaster from './addEditComponentMaster';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'componentMaster',
  components: {
    DatePicker,
    addEditComponentMaster
  },
  watch: {
    currentPage: function() {
      this.getComponentMasterList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getComponentMasterList();
    }
  },
  data() {
    return {
      payload: null,
      selectedCompMasterData: null,
      showComponentMasterModal: false,
      loader: false,
      unsubscribe: null,
      totalRows: null,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      componentMaster: null,
      vsetCode: null,
      showValueSetModal: false,
      setTimeVsetCode: null,
      componentGroup: {
        id: null,
        name: null
      },
      componentCode: null,
      componentName: null,
      displayName: null,
      startDate: null,
      endDate: null,
      componentMasterData: [],
      componentMasterFields: [
        {
          key: 'group_name'
        },
        {
          key: 'group_code'
        },
        {
          key: 'comp_code',
          label: 'Component Code'
        },
        {
          key: 'comp_name',
          label: 'Component Name'
        },
        {
          key: 'comp_display_name',
          label: 'Display Name'
        },
        {
          key: 'uom_meaning',
          label: 'UOM Class'
        },
        {
          key: 'uom',
          label: 'UOM'
        },
        {
          key: 'comp_revenue_Acc_meaning',
          label: 'Revenue Account'
        },
        {
          key: 'comp_waiver_Acc',
          label: 'Waiver Account'
        },
        {
          key: 'sac',
          label: 'SAC Code'
        },
        {
          key: 'hsn',
          label: 'HSN Code'
        },
        {
          key: 'start_date',
          label: 'Start Date'
        },
        {
          key: 'end_date',
          label: 'End Date'
        },
        {
          key: 'tolerance_rate_up',
        },
        {
          key: 'tolerance_rate_down',
        },
        {
          key: 'active',
          label: 'Active'
        }
      ],
      active: true
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.selectedCompMasterData = null;
          this.showHideComponentMasterModal(true);
        }
        if (actionName === 'download' && !this.showComponentMasterModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'masterData/getComponentMasterList',
            'component-master',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getComponentMasterList() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        component_group_id: this.componentGroup.id,
        component_code: this.componentCode,
        component_name: this.componentName,
        display_name: this.displayName,
        start_date: this.startDate,
        end_date: this.endDate,
        active: this.active
      };
      this.loader = true;
      this.$store
        .dispatch('masterData/getComponentMasterList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.componentMasterData = response.data.data.page;
            this.componentMasterData.active = response.data.data.page.map(
              data =>
                data.active ? (data.active = 'Yes') : (data.active = 'No')
            );
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.FMS_COMP_GROUP_TABLE) {
        this.componentGroup = {
          id: item.value_set_dtl_id,
          name: item.value_code
        };
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    showHideComponentMasterModal(flag) {
      this.showComponentMasterModal = flag;
    },
    rowSelected(item) {
      this.selectedCompMasterData = { ...item };
      this.showHideComponentMasterModal(true);
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.startDate);
    },
    clearSearch() {
      this.componentGroup = {
        id: null,
        name: null
      };
      this.componentCode = null;
      this.componentName = null;
      this.displayName = null;
      this.startDate = null;
      this.endDate = null;
      this.componentMasterData = [];
      this.totalRows = null;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.FMS_COMP_GROUP_TABLE) {
        this.componentGroup = {
          id: null,
          name: null
        };
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
